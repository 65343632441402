const Glide = require('@glidejs/glide');

let instanceSlider: any;

export class Slider {
    selector: string;

    constructor(selector: string) {
        this.selector = selector;
        this.updateSlider();

        var w = window.innerWidth;
       
        window.addEventListener("resize", () => {
            if (w != $(window).width()) {
                window.requestAnimationFrame(this.updateSlider);
                w = window.innerWidth;
            }
        });
    }

    updateSlider() {
        const width = window.innerWidth;
        const dekstopSizeCard = 576;
        const mobileSizeCard = 256;
        const isMobile = (width < 768);
        const offset = isMobile ? (width - mobileSizeCard) / 2 : (width - dekstopSizeCard) / 2;
        const settings = {
            type: "carousel",
            perView: 1,
            dragThreshold: isMobile ? 120 : false,
            swipeThreshold: isMobile ? 80 : false,
            gap: isMobile ? 12 : 32,
            startAt: isMobile ? 0 : 1,
            peek: isMobile
                ? { before: 8, after: ((offset * 2) - 8) }
                : { before: offset, after: offset }
        };

        if (!instanceSlider) {
            instanceSlider = new Glide.default(this.selector, settings);
            instanceSlider.mount();
        } else {
            instanceSlider.update(settings);
        }
    }
}